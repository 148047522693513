import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Header/Navbar";
import Footer from "../../Components/Footer/Footer";
import { fetchProductsByCategory } from "../../Services/api"; // Import the API service
import HoverBox from "../../Components/HoverBox/HoverBox"; // Import the HoverBox component

const AirborneP = () => {
  const [products, setProducts] = useState([]); // State to store products
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state for better error handling

  // Fetch products when the component mounts
  useEffect(() => {
    const loadProducts = async () => {
      try {
        setLoading(true);
        const data = await fetchProductsByCategory("Airborne"); // Fetch products for "Airborne" category
        setProducts(data); // Update state with fetched products
        setLoading(false);
      } catch (err) {
        console.error("Error fetching Airborne products:", err);
        setError("Failed to load products. Please try again later.");
        setLoading(false);
      }
    };
    loadProducts();
  }, []);

  // Handle button click for a product
  const handleProductClick = (productId) => {
    console.log(`Product clicked: ${productId}`);
    // Add your logic here (e.g., navigate to product details page)
  };

  // Function to get the proper image URL from the product
  const getImageUrl = (product) => {
    if (!product.images || product.images.length === 0) {
      return ""; // Return empty string if no images
    }

    const firstImage = product.images[0];

    // Check if the image is a URL string directly or an object with a URL property
    if (typeof firstImage === "string") {
      return firstImage;
    } else if (typeof firstImage === "object") {
      // If it's an object, try common URL properties
      return (
        firstImage.url ||
        firstImage.path ||
        firstImage.src ||
        firstImage.imageUrl ||
        ""
      );
    }

    return "";
  };

  // Display loading spinner while data is being fetched
  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#132043]"></div>
        </div>
        <Footer />
      </div>
    );
  }

  // Display error message if fetching fails
  if (error) {
    return (
      <div>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center p-8 max-w-md bg-red-50 rounded-lg">
            <h2 className="text-xl text-red-600 mb-2">Error</h2>
            <p>{error}</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div id="Airborne">
      <Navbar />
      <div className="p-8 rounded-2xl">
        <h1 className="text-2xl font-bold text-[#132043] text-center mb-6">
          Airborne Products
        </h1>
        <div className="flex flex-wrap justify-center gap-16">
          {products.length > 0 ? (
            products.map((product) => {
              const imageUrl = getImageUrl(product);
              console.log("Product:", product.name, "Image URL:", imageUrl); // Debug log

              return (
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
                  <HoverBox
                    key={product._id}
                    title={product.name}
                    image={imageUrl}
                    id={product._id}
                    onClick={() => handleProductClick(product._id)}
                  />
                </div>
              );
            })
          ) : (
            <p className="text-center py-10 text-gray-600">
              No products found in this category.
            </p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AirborneP;
