import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/auth/login`,

        { email, password }
      );
      if (response.data && response.data.status === "success") {
        const adminUser = response.data.data.user;
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(adminUser);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  };

  const logout = (navigate) => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/AdminPanelLogin");
  };

  const createProduct = async (productData) => {
    if (!user || !user.token) {
      throw new Error("You are not logged in. Please log in to get access.");
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/products`,

        productData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Product creation error:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
      }
      throw new Error("Failed to create product");
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, createProduct }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
