import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProducts } from "../../../../Contexts/ProductContext";
// import { image } from "../../../../../../backend/config/cloudinary";

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getProduct, updateProduct } = useProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    images: [],
  });

  const [imagePreview, setImagePreview] = useState([]);
  const [existingImages, setExistingImages] = useState([]);

  // Fetch existing product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const product = await getProduct(id);
        if (!product) return setError("Product not found");

        setFormData({
          name: product.name,
          description: product.description,
          category: product.category,
          images: [],
        });
        setExistingImages(product.images || []);
      } catch (err) {
        console.error("Error fetching product:", err);
        setError("Failed to load product details");
      }
    };

    fetchProduct();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => file.type.startsWith("image/"));

    if (validFiles.length !== files.length) {
      setError("Some files were not images and were excluded");
    }

    const newPreviews = validFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setImagePreview((prev) => [...prev, ...newPreviews]);
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...validFiles],
    }));
  };

  const removeImage = useCallback(
    (index) => {
      URL.revokeObjectURL(imagePreview[index].preview);
      setImagePreview((prev) => prev.filter((_, i) => i !== index));
      setFormData((prev) => ({
        ...prev,
        images: prev.images.filter((_, i) => i !== index),
      }));
    },
    [imagePreview]
  );

  const removeExistingImage = async (index) => {
    const imageToRemove = existingImages[index];

    if (!imageToRemove || !imageToRemove.public_id) {
      setError("Failed to identify image.");
      return;
    }
    console.log("imageToRemove", imageToRemove);
    const publicId = imageToRemove.public_id.slice(
      9,
      imageToRemove.public_id.length
    );
    console.log("publicId", publicId);
    const encodedImageId = encodeURIComponent(imageToRemove.public_id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/products/${id}/images/${encodedImageId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok)
        throw new Error(data.message || "Failed to delete image");

      // Remove image from state after successful deletion
      setExistingImages((prev) => prev.filter((_, i) => i !== index));
      setSuccessMessage("Image deleted successfully!");
    } catch (error) {
      console.error("Error deleting image:", error);
      setError(error.message);
    }
  };

  const validateForm = () => {
    if (!formData.name.trim()) throw new Error("Product name is required");
    if (!formData.category.trim()) throw new Error("Category is required");
    if (!formData.description.trim())
      throw new Error("Description is required");
    if (formData.images.length === 0 && existingImages.length === 0)
      throw new Error("At least one image is required");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setIsLoading(true);

    try {
      validateForm();

      const productData = new FormData();
      productData.append("name", formData.name.trim());
      productData.append("description", formData.description.trim());
      productData.append("category", formData.category.trim());

      // Append existing images as a separate field
      if (existingImages.length > 0) {
        productData.append("existingImages", JSON.stringify(existingImages));
      }

      // Append new images
      if (formData.images.length > 0) {
        formData.images.forEach((image, index) => {
          productData.append("newImages", image); // Using a different key for new images
        });
      }

      // Debugging: Log FormData content
      for (let pair of productData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const response = await updateProduct(id, productData);

      if (response) {
        setSuccessMessage("Product updated successfully!");
        navigate("/admin/products/view/" + id);
      } else {
        throw new Error(response?.message || "Failed to update product");
      }
    } catch (err) {
      console.error("Error updating product:", err);
      setError(err.message || "Failed to update product");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto py-10 px-4">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-semibold mb-6">Edit Product</h2>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4"
            role="alert"
          >
            {error}
          </div>
        )}

        {successMessage && (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4"
            role="alert"
          >
            {successMessage}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Product Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter product name"
            />
          </div>

          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a category</option>
              <option value="Embedded">Embedded</option>
              <option value="RF & Microwave">RF & Microwave</option>
              <option value="Airborne">Airborne</option>
              <option value="Electromechanical">Electromechanical</option>
              <option value="Systems & Solutions">Systems & Solutions</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter product description"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Existing Images
            </label>
            <div className="grid grid-cols-3 gap-4">
              {existingImages.map((image, index) => (
                <div key={index} className="relative">
                  <img
                    src={image.url}
                    alt={`Existing ${index + 1}`}
                    className="w-full h-32 object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => removeExistingImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                    aria-label="Remove image"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div>
            <label
              htmlFor="images"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Add New Images
            </label>
            <input
              id="images"
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>

          {imagePreview.length > 0 && (
            <div className="grid grid-cols-3 gap-4">
              {imagePreview.map((preview, index) => (
                <div key={index} className="relative">
                  <img
                    src={preview.preview}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-32 object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                    aria-label="Remove image"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex gap-4">
            <button
              type="submit"
              disabled={isLoading}
              className={`flex-1 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "Updating..." : "Update Product"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/admin/products/view/" + id)}
              className="flex-1 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProduct;
