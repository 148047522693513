import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import Home from "./Pages/Home/Home";
import Industries from "./Pages/Industries/Industries";
import Conceptulization from "./Pages/Conceptualization/Conceptualization";
import Software from "./Pages/Software/Software";
import Hardware from "./Pages/Hardware/Hardware";
import RegulatoryCompliance from "./Pages/RegulatoryCompliance/RegulatoryCompliance";
import Products from "./Pages/Products/Products";
import AdminPanelDashboard from "./Pages/AdminPanel/AdminPanelDashboard/AdminPanelDashboard";
import CreateProducts from "./Pages/AdminPanel/Products/CreateProducts/Createproducts";
import Login from "./Pages/AdminPanel/Login/Login";
import EditProducts from "./Pages/AdminPanel/Products/EditProducts/EditProducts";
import ViewProducts from "./Pages/AdminPanel/Products/ViewProducts/ViewProducts";
import EmbeddedP from "./Pages/EmbeddedP/EmbeddedP";
import ElectromechanicalP from "./Pages/ElectromechanicalP/ElectromechanicalP";
import AirborneP from "./Pages/Airbornep/AirborneP";
import RFP from "./Pages/RFP/RFP";
import SolutionP from "./Pages/SolutionP/SolutionP"
import SingleProduct from "./Pages/SingleProduct/SingleProduct";



import { AuthProvider } from "./Contexts/AuthContext";
import { ProductProvider } from "./Contexts/ProductContext";
import ProtectedRoute from "./Components/ProtectedRoutes/ProtectedRoute";

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "Industries", element: <Industries /> },
  { path: "Services", element: <Conceptulization /> },
  { path: "RegulatoryCompliance", element: <RegulatoryCompliance /> },
  { path: "Software", element: <Software /> },
  { path: "Hardware", element: <Hardware /> },
  { path: "Products", element: <Products /> },
  { path: "EmbeddedP", element: <EmbeddedP /> },
  { path: "ElectromechanicalP", element: <ElectromechanicalP /> },
  { path: "AirborneP", element: <AirborneP /> },
  { path: "RFP", element: <RFP /> },
  { path: "SolutionP", element: <SolutionP /> },
  { path: "SingleProduct/:id", element: <SingleProduct /> },
  { path: "AdminPanelLogin", element: <Login /> },
  {
    path: "/admin",
    element: <ProtectedRoute element={<AdminPanelDashboard />} />,
  },
  {
    path: "/admin/products/create",
    element: <ProtectedRoute element={<CreateProducts />} />,
  },
  {
    path: "/admin/products/edit/:id",
    element: <ProtectedRoute element={<EditProducts />} />,
  },
  {
    path: "/admin/products/view/:id",
    element: <ProtectedRoute element={<ViewProducts />} />,
  },
]);

// console.log('test');
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AuthProvider>
      <ProductProvider>
        {" "}
        {/* Wrap the router inside ProductProvider */}
        <RouterProvider router={router} />
      </ProductProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
