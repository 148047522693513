import React from "react";

// Define the Content1 component
function Content1() {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <section>
        <div className="p-8 rounded-2xl">
          <h2 className="text-lg mb-4 text-center text-[#132043]">
            We follow a rigorous design process to develop high-quality hardware
            products. Our process includes:
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 justify-center">
            {/* Row 1 */}
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Requirements Analysis:
                </div>
                <p className="text-base pb-2">
                  Defining product specifications and key performance
                  parameters.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Conceptual Design:
                </div>
                <p className="text-base pb-2">
                  Generating innovative hardware concepts and solutions.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Schematic Capture and Simulation:
                </div>
                <p className="text-base">
                  Creating detailed schematics and verifying circuit
                  functionality through simulations.
                </p>
              </div>
            </div>
            {/* Row 2 */}
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  PCB Layout and Design:
                </div>
                <p className="text-base pb-2">
                  Designing efficient, reliable PCBs with a focus on signal
                  integrity, power distribution, and thermal management.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Prototyping:
                </div>
                <p className="text-base pb-2">
                  Developing prototypes for testing and validation.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Testing and Verification:
                </div>
                <p className="text-base">
                  Ensuring product performance, reliability, and standards
                  compliance through thorough testing.
                </p>
              </div>
            </div>
            {/* Row 3 */}
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto col-span-1 lg:col-span-1 lg:col-start-2">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Manufacturing Support:
                </div>
                <p className="text-base">
                  Assisting with production, including documentation, design
                  transfer, and quality control.
                </p>
              </div>
            </div>
          </div>
          <h2 className="text-2xl font-bold mb-4 p-8 text-[#132043] text-center">
            We use industry-leading tools to optimize the design process,
            ensuring your product meets the highest standards of quality and
            efficiency.
          </h2>
        </div>
      </section>
    </div>
  );
}

export default Content1;
