// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/R10.png";

function Content4() {
  return (
    <section id="PRC" className="p-8 rounded-2xl bg-[#CACFD6]">
      <div className="p-8">
        <div className="flex flex-col-reverse md:flex-row-reverse gap-4">
          {/* Image Section */}
          <div className="w-full md:w-1/3 flex items-center justify-center">
            <img
              src={img}
              alt="Picture 2"
              className="w-full object-cover rounded-2xl"
            />
          </div>

          {/* Text Section */}
          <div className="w-full md:w-2/3">
            <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
              Product Compliance
            </h1>
            <p className="mb-4 md:mb-0 md:mr-4 text-lg">
              SGES ensures your products meet regulatory requirements for market
              access and customer confidence. Our services include:
            </p>
            <ul className="list-disc pl-5 text-lg">
              <li>
                <span className="font-bold">EMC:</span> Compliance with
                MIL-STD-461 for electromagnetic compatibility.
              </li>
              <li className="pt-2">
                <span className="font-bold">Power Quality:</span> Adherence to
                MIL-STD-704 for power quality.
              </li>
              <li className="pt-2">
                <span className="font-bold">Human Factors:</span> Compliance
                with MIL-STD-1472 for user experience and safety.
              </li>
              <li className="pt-2">
                <span className="font-bold">Safety and Reliability:</span>{" "}
                Meeting safety standards and conducting FMECA.
              </li>
              <li className="pt-2">
                <span className="font-bold">Material and Finish:</span>{" "}
                Compliance with MIL-HDBK-5400 and AMS-STD-595.
              </li>
            </ul>
            <p className="mb-4 md:mb-0 md:mr-4 text-lg">
              We work closely with certification bodies to streamline the
              process and minimize delays.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Content4;
