// Import the necessary dependencies
import React from "react";
import Navbar from "../../Components/Header/Navbar";
import Footer from "../../Components/Footer/Footer";

import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";
import Content8 from "./Content8";

const Products = () => {
  return (
    <div>
      <Navbar />
      <Content1 />
      <Content2 />
      <Content3 />
      <Content4 />
      <Content5 />
      <Content8 />
      <Footer />
    </div>
  );
};

export default Products;
