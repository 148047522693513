import React from "react";
import img from "../../Assets/images/R13.png";

function Content3() {
  return (
    <div id="Health">
      <section className="p-8 rounded-2xl bg-[#CACFD6]">
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center">
            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Healthcare
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The healthcare sector demands innovative solutions that
                prioritize patient safety and efficiency. SGES offers a
                comprehensive range of services to address the unique challenges
                faced by the medical industry. Our expertise includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">
                    Medical Device Development:{" "}
                  </span>
                  Designing, developing, and manufacturing cutting-edge medical
                  devices that meet stringent regulatory requirements.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Software Development: </span>{" "}
                  Creating software solutions for medical devices, adhering to
                  IEC 62304 standards for safety and effectiveness.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Regulatory Compliance: </span>{" "}
                  Navigating complex regulatory landscapes to ensure product
                  approval and market access.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Quality Assurance: </span>{" "}
                  Implementing robust quality management systems to maintain
                  product excellence.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                By combining technical expertise with a deep understanding of
                the healthcare industry, we deliver solutions that improve
                patient outcomes and drive industry advancements.
              </p>
            </div>

            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 2"
                className="w-full md:w-auto sm:h-80 object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content3;
