import React from "react";
import img from "../../Assets/images/R8.png";

function Content3() {
  return (
    <div id="NCS">
      <section className="text-justify bg-[#CACFD6] rounded-2xl">
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 2"
                className="w-full md:w-auto  object-cover rounded-2xl"
              />
            </div>

            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043]">
                Networking and Communication Software
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                At SGES, we have a deep understanding of network protocols and
                architectures, allowing us to develop robust communication
                solutions across various technologies:
              </p>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">
                    Network Protocol Implementation:
                  </span>{" "}
                  Custom protocols and stacks for specific needs.
                </li>
                <li className="pt-4">
                  <span className="font-bold">IP Networking: </span> Designing
                  and implementing TCP/IP applications and services.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    High-Performance Networking:
                  </span>{" "}
                  Enhancing performance with technologies like DPDK, SR-IOV, and
                  AVX-512.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Virtualized Networking: </span>
                  Developing functions for virtualization environments.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Network Management: </span>
                  Creating tools for monitoring and managing networks.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                Our strong networking expertise ensures innovative and reliable
                solutions for your network infrastructure.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content3;
