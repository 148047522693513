import React from "react";
import img from "../../Assets/images/R12.png";
import Aero from "../../Components/Card/Aero";
import Health from "../../Components/Card/Health";
import Auto from "../../Components/Card/Auto";
import Railway from "../../Components/Card/Railway";

function Content2() {
  return (
    <div id="Airborne">
      <section>
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4">
            <div>
              <h1 className="text-2xl font-bold mb-4 c text-[#132043] pt-8 text-center md:text-left">
                Airborne
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The aerospace and defense sectors require unparalleled
                reliability, safety, and performance. SGES offers a complete
                suite of services designed to meet the exacting demands of this
                industry, including:
              </p>
              <div className="p-8 rounded-2xl">
                <div className="flex flex-wrap justify-center gap-16">
                  {/* Card Container */}
                  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
                    <Aero />
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
                    <Health />
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
                    <Auto />
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex justify-center">
                    <Railway />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content2;
