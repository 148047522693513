import React from "react";
import CF1 from "../../Components/CardFlip/CF1";
import CFSA from "../../Components/CardFlip/CFSA";
import CFAME from "../../Components/CardFlip/CFAME";
import CFPCB from "../../Components/CardFlip/CFPCB";
import CFRF from "../../Components/CardFlip/CFRF";
import CFASD from "../../Components/CardFlip/CFASD";
import CFRL from "../../Components/CardFlip/CFRL";
import CFBSP from "../../Components/CardFlip/CFBSP";
import CFNCS from "../../Components/CardFlip/CFNCS";
import CFPC from "../../Components/CardFlip/CFPC";
import CFPRC from "../../Components/CardFlip/CFPRC";
import CFCS from "../../Components/CardFlip/CFCS";
import CardFlip from "../../Components/CardFlip/CardFlip";

function Content1() {
  return (
    <div className="p-8 bg-[#CACFD6] rounded-2xl">
      <h1 className="text-2xl font-bold text-[#132043] mb-4 text-center">
        Our Services
      </h1>

      <div className="flex flex-wrap justify-center items-center">
        {/* Cards */}
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CF1 />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFSA />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFAME />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFPCB />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFRF />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFASD />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFRL />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFBSP />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFNCS />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFPC />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFPRC />
        </div>
        <div className="w-full sm:w-1/2 lg:w-[30%] p-2 flex justify-center">
          <CFCS />
        </div>
      </div>
    </div>
  );
}

export default Content1;
