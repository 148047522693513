import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Contexts/AuthContext";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("error"); // 'success' or 'error'
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure both fields are filled
    if (!email || !password) {
      setToastMessage("Please fill in all fields");
      setToastSeverity("error");
      setShowToast(true);
      return;
    }

    // Call login function and await result
    const success = await login(email, password);

    // Show appropriate message based on login result
    if (success) {
      setToastMessage("Login successful!");
      setToastSeverity("success");
      setShowToast(true);

      // Navigate to dashboard after a short delay
      navigate("/admin");
      // setTimeout(() => {
      // }, 1000);
    } else {
      setToastMessage("Invalid credentials");
      setToastSeverity("error");
      setShowToast(true);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 shadow-lg rounded-xl w-96">
        <h2 className="text-2xl font-semibold text-center mb-4">Admin Login</h2>
        <form onSubmit={handleSubmit} className="flex flex-col">
          <label className="mb-2 text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            className="mb-4 p-2 border rounded-md focus:ring-2 focus:ring-blue-500 outline-none"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label className="mb-2 text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            className="mb-4 p-2 border rounded-md focus:ring-2 focus:ring-blue-500 outline-none"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-md transition duration-200"
          >
            Sign in
          </button>
        </form>
      </div>

      {/* Toast Notification */}
      {showToast && (
        <div
          className={`fixed top-5 px-4 py-2 rounded-md text-white text-sm shadow-lg transition ${
            toastSeverity === "error" ? "bg-red-500" : "bg-green-500"
          }`}
        >
          {toastMessage}
        </div>
      )}
    </div>
  );
};

export default Login;
