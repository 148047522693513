import React from "react";
import img from "../../Assets/images/R4.png";

function Content2() {
  return (
    <div id="Analog">
      <section>
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 2"
                className="w-full md:w-auto  object-cover rounded-2xl"
              />
            </div>

            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3 pr-8 flex flex-col">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Core Hardware Capabilities
              </h1>
              <h1 className="text-lg font-bold mb-4 text-[#132043]">
                Analog/Digital & Mixed Signal Electronics
              </h1>
              <p className="mb-4 text-lg">
                SGES specializes in designing complex analog, digital, and
                mixed-signal electronic systems across various applications,
                including:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">Data Acquisition (DAQ):</span>{" "}
                  Precision data conversion and conditioning for sensor systems.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Audio/Video Processing:</span>{" "}
                  High-performance signal processing for consumer electronics
                  and professional applications.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Baseband Processing:</span>{" "}
                  Efficient baseband circuit design for communication systems.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Discrete Electronics:</span>{" "}
                  Custom circuit design tailored to specific needs.
                </li>
              </ul>
              <p className="text-lg pt-2">
                Our engineers have a deep understanding of circuit theory and
                design principles, allowing us to deliver optimal solutions for
                your requirements.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content2;