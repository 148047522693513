import React from "react";

// Define the Content1 component
function Content1() {
  return (
    <div id="CS" className="flex justify-center items-center min-h-screen">
      <section>
        <div className="p-8 rounded-2xl">
          <h1 className="text-2xl font-bold mb-4 text-center">
            <span className="inline-block rounded-full px-3 py-1 text-2xl font-bold mr-2 mb-2">
              Certification Support
            </span>
          </h1>
          <h2 className="text-lg mb-4 text-center text-[#132043]">
            SGES provides comprehensive support for a smooth certification
            process, including:
          </h2>

          {/* Responsive grid for the first row */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 justify-center">
            {/* Row 1 */}
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Certification Planning:
                </div>
                <p className="text-base pb-2">
                  Tailoring strategies for your product and markets.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Documentation Preparation:
                </div>
                <p className="text-base pb-2">
                  Creating and managing necessary documents, including test
                  reports and user manuals.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Certification Testing:
                </div>
                <p className="text-base">
                  Coordinating and overseeing compliance tests.
                </p>
              </div>
            </div>
          </div>

          {/* Responsive grid for the second row */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 mt-8 justify-center">
            {/* Row 2 - Centered */}
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col lg:ml-20 mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Certification Submission and Management:
                </div>
                <p className="text-base pb-2">
                  Handling submissions and liaising with authorities.
                </p>
              </div>
            </div>
            <div className="max-w-xs w-full h-40 bg-[#CACFD6] rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Remediation Support:
                </div>
                <p className="text-base pb-2">
                  Addressing deficiencies and implementing corrective actions.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-2xl mb-4 p-8 text-[#132043] text-center font-bold">
            Our expertise helps minimize delays and optimize your
            time-to-market.
          </h2>
        </div>
      </section>
    </div>
  );
}

export default Content1;
