import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../Components/Header/Navbar";
import Footer from "../../Components/Footer/Footer";
import ContactForm from "../../Components/ContactForm/PContactForm";

const SingleProduct = () => {
  const { id } = useParams(); // Get the product ID from URL params
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const autoPlayTimeoutRef = useRef(null);
  const pauseTimeoutRef = useRef(null);

  // Fetch product data when component mounts or ID changes
  useEffect(() => {
    const fetchProductById = async (id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/products/${id}`
        );
        const data = await response.json();
        console.log("Product data:", data);
        return data;
      } catch (error) {
        console.error("Error fetching product details:", error);
        return null;
      }
    };

    const getProductDetails = async () => {
      try {
        setLoading(true);
        const data = await fetchProductById(id);
        if (data && data.status === "success") {
          setProduct(data.data); // Access the data property from the response
        } else {
          setError("Product not found or error fetching data");
        }
        setLoading(false);
      } catch (err) {
        console.error("Error in getProductDetails:", err);
        setError("Failed to load product details. Please try again later.");
        setLoading(false);
      }
    };

    if (id) {
      getProductDetails();
    }
  }, [id]);

  // Effect for auto-transitioning images
  useEffect(() => {
    const startAutoPlay = () => {
      if (
        product &&
        product.images &&
        product.images.length > 1 &&
        isAutoPlaying
      ) {
        // Clear any existing timeout
        if (autoPlayTimeoutRef.current) {
          clearTimeout(autoPlayTimeoutRef.current);
        }

        // Set a new timeout for 5 seconds
        autoPlayTimeoutRef.current = setTimeout(() => {
          setCurrentImageIndex((prevIndex) =>
            prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
          );
        }, 5000);
      }
    };

    startAutoPlay();

    // Cleanup function to clear timeout when component unmounts or dependencies change
    return () => {
      if (autoPlayTimeoutRef.current) {
        clearTimeout(autoPlayTimeoutRef.current);
      }
      if (pauseTimeoutRef.current) {
        clearTimeout(pauseTimeoutRef.current);
      }
    };
  }, [currentImageIndex, product, isAutoPlaying]);

  // Navigate to the next image in carousel
  const nextImage = () => {
    if (product && product.images && product.images.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  // Navigate to the previous image in carousel
  const prevImage = () => {
    if (product && product.images && product.images.length > 0) {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
      );
    }
  };

  // Handle dot click - pause auto transition for 15 seconds
  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
    setIsAutoPlaying(false);

    // Clear existing pause timeout if any
    if (pauseTimeoutRef.current) {
      clearTimeout(pauseTimeoutRef.current);
    }

    // Resume auto-playing after 15 seconds
    pauseTimeoutRef.current = setTimeout(() => {
      setIsAutoPlaying(true);
    }, 15000);
  };

  // Handle mouse enter - pause auto transition
  const handleMouseEnter = () => {
    setIsAutoPlaying(false);
  };

  // Handle mouse leave - resume auto transition
  const handleMouseLeave = () => {
    setIsAutoPlaying(true);
  };

  // Parse description into first paragraph and bullet points
  const parseDescription = (description) => {
    if (!description) return { firstParagraph: "", bulletPoints: [] };

    // Split the description by newline characters
    const parts = description.split("\r\n");

    // First part is the paragraph
    const firstParagraph = parts[0] || "";

    // The rest are potential bullet points
    const bulletPoints = parts.slice(1).filter((point) => point.trim() !== "");

    return { firstParagraph, bulletPoints };
  };

  // Get the current image URL
  const getCurrentImageUrl = () => {
    if (!product || !product.images || product.images.length === 0) {
      return "";
    }

    const image = product.images[currentImageIndex];
    return image.url || "";
  };

  // Get a thumbnail URL for the image gallery
  const getThumbnailUrl = (image) => {
    if (!image) return "";
    return image.url || "";
  };

  if (loading) {
    return (
      <div>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-[#132043]"></div>
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center p-8 max-w-md bg-red-50 rounded-lg">
            <h2 className="text-lg text-red-600 mb-2">Error</h2>
            <p className="text-md">{error}</p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!product) {
    return (
      <div>
        <Navbar />
        <div className="min-h-screen flex items-center justify-center">
          <div className="text-center p-8">
            <h2 className="text-lg mb-2">Product Not Found</h2>
            <p className="text-md">
              The product you're looking for doesn't seem to exist.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  // Parse the description
  const { firstParagraph, bulletPoints } = parseDescription(
    product.description
  );

  return (
    <div>
      <Navbar />
      <div className="container mx-auto px-4 py-6">
        {/* Main content - Flex row for desktop, column for mobile */}
        <div className="flex flex-col md:flex-row gap-6">
          {/* Image Carousel - 1/3 width on desktop, full width on mobile */}
          <div className="w-full md:w-1/3 flex flex-col items-center justify-center">
            <div className="w-full">
              <div
                className="relative h-64 md:h-72 bg-gray-100 rounded-lg overflow-hidden"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {/* Main Image */}
                {getCurrentImageUrl() ? (
                  <div
                    className="w-full h-full bg-center bg-contain bg-no-repeat"
                    style={{ backgroundImage: `url(${getCurrentImageUrl()})` }}
                  ></div>
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-200">
                    <span className="text-gray-500 text-md">
                      No image available
                    </span>
                  </div>
                )}

                {/* Carousel Controls - Only show if multiple images */}
                {product.images && product.images.length > 1 && (
                  <div className="absolute inset-0 flex items-center justify-between">
                    <button
                      onClick={prevImage}
                      className="ml-1 p-1 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 transition"
                      aria-label="Previous image"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={nextImage}
                      className="mr-1 p-1 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 transition"
                      aria-label="Next image"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>

              {/* Thumbnail Preview */}
              {product.images && product.images.length > 1 && (
                <div className="mt-2 flex space-x-1 overflow-x-auto pb-1">
                  {product.images.map((image, index) => {
                    const thumbnailUrl = getThumbnailUrl(image);
                    return (
                      <button
                        key={index}
                        onClick={() => handleDotClick(index)}
                        className={`w-12 h-12 flex-shrink-0 rounded border ${
                          index === currentImageIndex
                            ? "border-[#132043]"
                            : "border-transparent"
                        }`}
                      >
                        {thumbnailUrl ? (
                          <div
                            className="w-full h-full bg-center bg-cover rounded"
                            style={{ backgroundImage: `url(${thumbnailUrl})` }}
                          ></div>
                        ) : (
                          <div className="w-full h-full flex items-center justify-center bg-gray-200">
                            <span className="text-xs text-gray-500">
                              No image
                            </span>
                          </div>
                        )}
                      </button>
                    );
                  })}
                </div>
              )}

              {/* Image Indicators - Dots */}
              {product.images && product.images.length > 1 && (
                <div className="mt-2 flex justify-center">
                  {product.images.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => handleDotClick(index)}
                      className={`mx-1 w-2 h-2 rounded-full ${
                        index === currentImageIndex
                          ? "bg-[#132043]"
                          : "bg-gray-300"
                      }`}
                      aria-label={`Go to image ${index + 1}`}
                    ></button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Product Details - 2/3 width on desktop, full width on mobile */}
          <div className="w-full md:w-2/3">
            <h1 className="text-xl font-bold text-[#132043] mb-3">
              {product.name}
            </h1>

            {/* First paragraph of description */}
            {firstParagraph && (
              <p className="text-md text-gray-700 mb-4">{firstParagraph}</p>
            )}

            {/* Specifications section - only show if bullet points exist */}
            {bulletPoints.length > 0 && (
              <div className="bg-white rounded-lg p-4 shadow-sm mb-4">
                <h2 className="text-lg font-semibold mb-2">Specifications</h2>
                <ul className="list-disc pl-5 space-y-1">
                  {bulletPoints.map((point, index) => (
                    <li key={index} className="text-md text-gray-700">
                      {point.trim()}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <ContactForm productName={product.name} />
      <Footer />
    </div>
  );
};

export default SingleProduct;
