import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

export default function Footer() {
  return (
    <MDBFooter
      className="text-center text-lg-start text-muted"
      class="bg-[#132043] rounded-md"
    >
      <section>
        <MDBContainer className="p-4  text-md-start ">
          <MDBRow className="mt-3">
            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-[#ffc501]">
                Services
              </h6>
              <p>
                <Link
                  to="/Services"
                  onClick={() => scrollToElement("Conceptualization")}
                  style={{ color: "white" }}
                >
                  Conceptualization
                </Link>
              </p>
              <p>
                <Link
                  to="/Hardware"
                  onClick={() => scrollToElement("H")}
                  style={{ color: "white" }}
                >
                  Embedded Systems Design Hardware
                </Link>
              </p>{" "}
              <p>
                <Link
                  to="/Software"
                  onClick={() => scrollToElement("S")}
                  style={{ color: "white" }}
                >
                  Embedded Systems Design Software
                </Link>
              </p>{" "}
              <p>
                <Link
                  to="/RegulatoryCompliance"
                  onClick={() => scrollToElement("C&C")}
                  style={{ color: "white" }}
                >
                  Certification & Compliance
                </Link>
              </p>{" "}
            </MDBCol>
            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <div>
                <h6 className="text-uppercase fw-bold mb-4 text-[#ffc501]">
                  Industries
                </h6>
                <p>
                  <Link
                    to="/Industries"
                    style={{ color: "white" }}
                    onClick={() => scrollToElement("Aero")}
                  >
                    Aerospace and Defence Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    style={{ color: "white" }}
                    onClick={() => scrollToElement("Health")}
                  >
                    Healthcare Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    style={{ color: "white" }}
                    onClick={() => scrollToElement("Auto")}
                  >
                    Automotive Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    style={{ color: "white" }}
                    onClick={() => scrollToElement("Railway")}
                  >
                    Railway Industry
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    style={{ color: "white" }}
                    onClick={() => scrollToElement("IOT")}
                  >
                    IoT and Connected Devices
                  </Link>
                </p>
                <p>
                  <Link
                    to="/Industries"
                    style={{ color: "white" }}
                    onClick={() => scrollToElement("CN")}
                  >
                    Communication and Networking
                  </Link>
                </p>
              </div>
            </MDBCol>
            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-[#ffc501]">
                Products
              </h6>
              <p>
                <Link
                  to="/EmbeddedP"
                  style={{ color: "white" }}
                  onClick={() => {
                    // This will ensure the page starts at the top when navigating
                    window.scrollTo(0, 0);
                  }}
                >
                  Embedded Modules
                </Link>
              </p>
              <p>
                <Link
                  to="/RFP"
                  style={{ color: "white" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  RF & Microwave Modules
                </Link>
              </p>
              <p>
                <Link
                  to="/AirborneP"
                  style={{ color: "white" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Airborne Modules
                </Link>
              </p>
              <p>
                <Link
                  to="/ElectromechanicalP"
                  style={{ color: "white" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Electromechanical Modules
                </Link>
              </p>
              <p>
                <Link
                  to="/solutionP"
                  style={{ color: "white" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Systems & Solutions
                </Link>
              </p>
            </MDBCol>

            <MDBCol
              md="4"
              lg="3"
              xl="3"
              className="mx-auto mb-md-0 mb-4 text-[#ffc501]"
            >
              <h6 className="text-uppercase text-left fw-bold mb-4">Contact</h6>

              <p
                onClick={() =>
                  (window.location.href = "mailto:info@siliconglen.co.uk")
                }
                style={{ cursor: "pointer" }}
              >
                <MDBIcon icon="envelope" className="me-3 text-white" />
                <span className="text-white">info@siliconglen.co.uk</span>
              </p>
              <div>
                <Link
                  to="https://www.linkedin.com/company/silicon-glen-embedded-systems?lipi=urn%3Ali%3Apage%3Ad_flagship3_company%3BzfueOrvdQvi6zV52uZRBgg%3D%3D"
                  className="me-4 text-white"
                >
                  <MDBIcon fab icon="linkedin" className="me-3 text-white" />
                  <span className="text-white">Contact us on LinkedIn</span>
                </Link>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        className="flex justify-center items-center flex-col-reverse  text-center p-4 text-white lg:gap-40 mt-[-3.3%]  md:flex-row gap-28 "
      >
        <div>
          © 2024 Copyright:&nbsp;
          <Link to="/" className=" fw-bold text-white">
            Siliconglen.co.uk
          </Link>
          <br />
          <Link to="https://hammad-ali.vercel.app/" className="mr-4 text-white">
            Web design by HA
          </Link>
        </div>
      </div>
    </MDBFooter>
  );
}
