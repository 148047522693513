// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/R16.png";

// Define the Home component
function Content5() {
  return (
    <div id="CN">
      <section className="p-8 rounded-2xl bg-[#CACFD6]">
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4">
            {/* Text Section */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Communication and Networking
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The communication and networking industry is undergoing rapid
                transformation with the advent of 5G, IoT, and cloud
                technologies. SGES offers comprehensive solutions to address the
                evolving needs of this dynamic sector. Our expertise includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">
                    Network Design and Architecture
                  </span>{" "}
                  Developing robust and scalable network infrastructures.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Network Security:</span>{" "}
                  Implementing advanced security measures to protect network
                  assets.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Wireless Communication: </span>{" "}
                  Designing and developing wireless communication systems and
                  devices.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Network Optimization:</span>{" "}
                  Improving network performance and efficiency through analysis
                  and optimization.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Cloud Integration:</span>{" "}
                  Integrating network infrastructure with cloud platforms for
                  enhanced scalability and flexibility.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                By combining our technical expertise with a deep understanding
                of network technologies, we deliver solutions that enable
                seamless communication and data transfer.
              </p>
            </div>

            {/* Image Section */}
            <div className="w-full md:w-1/3 flex items-center justify-center">
              <img
                src={img}
                alt="Picture 2"
                className="w-full sm:h-80 object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content5;
