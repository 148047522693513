import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useProducts } from "../../../../Contexts/ProductContext";

const CreateProduct = () => {
  const navigate = useNavigate();
  const { createProduct } = useProducts();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    images: [],
  });

  const [imagePreview, setImagePreview] = useState([]);

  // Cleanup function for image previews
  useEffect(() => {
    return () => {
      imagePreview.forEach((preview) => {
        URL.revokeObjectURL(preview.preview);
      });
    };
  }, [imagePreview]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) => file.type.startsWith("image/"));

    if (validFiles.length !== files.length) {
      setError("Some files were not images and were excluded");
    }

    const newPreviews = validFiles.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
    }));

    setImagePreview((prev) => [...prev, ...newPreviews]);
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...validFiles],
    }));
  };

  const removeImage = useCallback(
    (index) => {
      URL.revokeObjectURL(imagePreview[index].preview);
      setImagePreview((prev) => prev.filter((_, i) => i !== index));
      setFormData((prev) => ({
        ...prev,
        images: prev.images.filter((_, i) => i !== index),
      }));
    },
    [imagePreview]
  );

  const validateForm = () => {
    if (!formData.name.trim()) throw new Error("Product name is required");
    if (!formData.category.trim()) throw new Error("Category is required");
    if (!formData.description.trim())
      throw new Error("Description is required");
    if (formData.images.length === 0)
      throw new Error("At least one image is required");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    setIsLoading(true);

    try {
      validateForm();

      const productData = new FormData();
      productData.append("name", formData.name.trim());
      productData.append("description", formData.description.trim());
      productData.append("category", formData.category.trim());

      formData.images.forEach((image) => {
        productData.append("images", image);
      });

      const response = await createProduct(productData);
      console.log("Product created successfully:", response);

      setSuccessMessage("Product created successfully!");

      // Clear form and previews
      setFormData({
        name: "",
        description: "",
        category: "",
        images: [],
      });

      imagePreview.forEach((preview) => {
        URL.revokeObjectURL(preview.preview);
      });
      setImagePreview([]);

      // Navigate after a brief delay to show success message
      setTimeout(() => {
        navigate("/admin");
      }, 1500);
    } catch (err) {
      console.error("Error creating product:", err);
      setError(err.message || "Failed to create product");
    } finally {
      setIsLoading(false);
    }
  };

  console.log("create product");
  return (
    <div className="container mx-auto py-10 px-4">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow p-6">
        <h2 className="text-2xl font-semibold mb-6">Create New Product</h2>

        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4"
            role="alert"
          >
            {error}
          </div>
        )}

        {successMessage && (
          <div
            className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4"
            role="alert"
          >
            {successMessage}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Product Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter product name"
            />
          </div>

          <div>
            <label
              htmlFor="category"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Category
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a category</option>
              <option value="Embedded">Embedded</option>
              <option value="RF & Microwave">RF & Microwave</option>
              <option value="Airborne">Airborne</option>
              <option value="Electromechanical">Electromechanical</option>
              <option value="Systems & Solutions">Systems & Solutions</option>
            </select>
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter product description"
            />
          </div>

          <div>
            <label
              htmlFor="images"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Product Images
            </label>
            <input
              id="images"
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
              className="w-full border border-gray-300 rounded p-2"
            />
          </div>

          {imagePreview.length > 0 && (
            <div className="grid grid-cols-3 gap-4 mt-4">
              {imagePreview.map((preview, index) => (
                <div key={index} className="relative">
                  <img
                    src={preview.preview}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-32 object-cover rounded"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                    aria-label="Remove image"
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          )}

          <div className="flex gap-4">
            <button
              type="submit"
              disabled={isLoading}
              className={`flex-1 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isLoading ? "Creating..." : "Create Product"}
            </button>
            <button
              type="button"
              onClick={() => navigate("/admin")}
              className="flex-1 bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateProduct;
