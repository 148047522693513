import React, { useRef } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const ServicesDropdown = () => {
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const toggleDropdown = () => {
    dropdownRef.current.style.display =
      dropdownRef.current.style.display === "block" ? "none" : "block";
    setPosition();
  };

  const setPosition = () => {
    const dropdown = dropdownRef.current;
    const button = dropdownButtonRef.current;
    if (dropdown && button) {
      const rect = button.getBoundingClientRect();
      dropdown.style.top = `${rect.bottom + window.scrollY}px`;
      dropdown.style.left = `${rect.left + window.scrollX}px`;
    }
  };

  const scrollToElement = (sectionName) => {
    setTimeout(() => {
      scroller.scrollTo(sectionName, {
        duration: 100,
        smooth: "easeInOutQuart",
        offset: 0,
      });
    }, 100);
    if (dropdownRef.current) {
      dropdownRef.current.style.display = "none";
    }
  };

  return (
    <div className="text-left">
      <div className="dropdown-content" ref={dropdownRef}>
        <MDBContainer
          class="bg-[#132043] rounded-md"
          className="absolute text-left mt-1 px-0" // Reduced margin-top and padding
        >
          <MDBRow className="mt-0 justify-content-center">
            {" "}
            {/* Centered columns */}
            {/* First column */}
            <MDBCol
              md="3"
              lg="2"
              xl="2"
              className="mb-0 pl-0 pr-0 py-0 text-left" // Reduced padding
            >
              <h5 className="text-uppercase fw-bold mb-0">
                {" "}
                {/* Reduced margin-bottom */}
                <Link
                  to="/Services"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                >
                  Conceptualization
                </Link>
              </h5>
              <p className="text-white mb-0">
                <Link
                  to="/Services"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("SystemEngineering1")}
                >
                  System Engineering
                </Link>
              </p>
              <p className="text-white mb-0">
                <Link
                  to="/Services"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("SystemArchitecture")}
                >
                  System Architecture
                </Link>
              </p>
            </MDBCol>
            {/* Second column */}
            <MDBCol md="3" lg="3" xl="3" className="mb-0 px-0 py-0 text-left">
              {" "}
              {/* Reduced padding */}
              <h5 className="text-uppercase fw-bold mb-0">
                {" "}
                {/* Reduced margin-bottom */}
                <Link
                  to="/hardware"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                >
                  Embedded Systems Design Hardware{" "}
                </Link>
              </h5>
              <p className="mb-0">
                <Link
                  to="/Hardware"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("Analog")}
                >
                  Analog/Digital & Mixed Signal Electronics
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/Hardware"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("PCB")}
                >
                  High-Speed PCB Design
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/Hardware"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("RF")}
                >
                  RF/Microwave Components/Modules
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/Hardware"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("AS")}
                >
                  Antenna/Sensors and Array Design
                </Link>
              </p>
            </MDBCol>
            {/* Third column */}
            <MDBCol md="3" lg="3" xl="3" className="mb-0 px-0 py-0 text-left">
              {" "}
              {/* Reduced padding */}
              <h5 className="text-uppercase fw-bold mb-0">
                {" "}
                {/* Reduced margin-bottom */}
                <Link
                  to="/Software"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                >
                  Embedded Systems design Software
                </Link>
              </h5>
              <p className="mb-0">
                <Link
                  to="/Software"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("RA")}
                >
                  Real-time Applications
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/Software"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("BSP")}
                >
                  BSP, Firmware, and Driver Development
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/Software"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("NCS")}
                >
                  Networking and Communication Software
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/Software"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("HPC")}
                >
                  High-Performance Computing
                </Link>
              </p>
            </MDBCol>
            {/* Fourth column */}
            <MDBCol md="2" lg="2" xl="2" className="mb-0 px-0 py-0 text-left">
              {" "}
              {/* Reduced padding */}
              <h5 className="text-uppercase fw-bold mb-0 whitespace-nowrap">
                {" "}
                {/* Reduced margin-bottom */}
                <Link
                  to="/RegulatoryCompliance"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                >
                  Certification & Compliance
                </Link>
              </h5>
              <p className="mb-0">
                <Link
                  to="/RegulatoryCompliance"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("PC")}
                >
                  Process Compliance
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/RegulatoryCompliance"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("PRC")}
                >
                  Product Compliance
                </Link>
              </p>
              <p className="mb-0">
                <Link
                  to="/RegulatoryCompliance"
                  style={{ textAlign: "left", display: "block", width: "100%" }}
                  onClick={() => scrollToElement("CS")}
                >
                  Certification Support
                </Link>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  );
};

export default ServicesDropdown;
