  import React from "react";
  import logo from "../../Assets/images/R7.png";
  import { Link } from "react-router-dom";
  import { scroller } from "react-scroll";

  const scrollToElement = (sectionName) => {
    setTimeout(() => {
      scroller.scrollTo(sectionName, {
        duration: 100,
        smooth: "easeInOutQuart",
        offset: 0,
      });
    }, 100);
  };

  const CardFlip = () => {
    return (
      <div className="p-4 rounded-2xl ">
        <div className="flip-card" tabIndex="0">
          <div className="flip-card-inner">
          <div className="flip-card-front rounded-lg overflow-hidden">
            <div className="image-container rounded-lg overflow-hidden">
              <div className="image rounded-lg overflow-hidden">
                <img src={logo} alt="Logo" className="rounded-lg scale-125" />
              </div>
              <div
                className={`absolute bottom-0 left-0 right-0 bg-[#132043]
          bg-opacity-50 text-center text-white font-bold text-lg transition-transform duration-300 ease-in-out p-4 `}
              >Real-time Applications</div>
              </div>
            </div>
            <div className="flip-card-back">
              <ul className="list-disc pl-5 p-4 text-left">
                <li>Real-time Operating System (RTOS) Integration</li>
                <li>Deterministic Scheduling</li>
                <li>High-Performance Computing </li>
                <li>ARINC 653 Compliance</li>
                <button className="bg-[#CACFD6] p-2 rounded-2xl mt-4">
                  <Link
                    to="/Software"
                    onClick={() => scrollToElement("RA")}
                    className="text-[#132043] font-bold"
                  >
                    Read more
                  </Link>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  export default CardFlip;
