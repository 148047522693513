import React, { useRef, useState } from "react";
import logo from "../../Assets/images/Picture16.jpeg"; // Importing the image

const ContactForm = ({ productName }) => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/product-contact`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            full_name: form.current.querySelector('[name="full_name"]').value,
            email_id: form.current.querySelector('[name="email_id"]').value,
            phone: form.current.querySelector('[name="phone"]').value,
            company: form.current.querySelector('[name="company"]').value,
            country: form.current.querySelector('[name="country"]').value,
            services: form.current.querySelector('[name="services"]').value,
            mode: form.current.querySelector('[name="mode"]').value,
            message: form.current.querySelector('[name="message"]').value,
            budget: form.current.querySelector('[name="budget"]').value,
            product_name: productName, // Include the product name in the submission
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      const data = await response.json();
      if (data.success) {
        alert(
          "Thank you for contacting us. Your Email was sent successfully. We will get back to you as soon as possible."
        );
        form.current.reset(); // Reset the form after successful submission
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(
        "Sorry your Email was not sent. Kindly try again. Thank you for contacting us."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      id="Contact"
      className="relative overflow-hidden rounded-lg w-full h-full"
    >
      {/* Background Image with Opacity */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{
          backgroundImage: `url(${logo})`,
          opacity: 0.5,
          zIndex: -1,
        }}
      ></div>

      <div>
        <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8 p-8 text-center">
          <span className="bg-[#CACFD6] rounded-2xl p-2">Get in touch</span>
        </h1>
      </div>

      {/* Content */}
      <div className="p-8 rounded-2xl bg-[#CACFD6] text-white z-10 lg:mx-40 md:mx-20 sm:mx-8">
        <section>
          <form ref={form} onSubmit={sendEmail} className="space-y-4">
            {/* Row 1: Full Name, Email, Phone */}
            <div className="flex flex-wrap gap-4">
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Full Name
                </label>
                <input
                  type="text"
                  name="full_name"
                  required
                  className="w-full p-2 rounded bg-white text-[#132043]"
                />
              </div>
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Email
                </label>
                <input
                  type="email"
                  name="email_id"
                  className="w-full p-2 rounded bg-white text-[#132043]"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Phone
                </label>
                <input
                  type="tel"
                  name="phone"
                  className="w-full p-2 rounded bg-white text-[#132043]"
                  required
                />
              </div>
            </div>

            {/* Row 2: Company, Country */}
            <div className="flex flex-wrap gap-4">
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Company
                </label>
                <input
                  type="text"
                  name="company"
                  className="w-full p-2 rounded bg-white text-[#132043]"
                  required
                />
              </div>
              <div className="flex-1">
                <label className="text-lg font-bold text-[#132043]">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  className="w-full p-2 rounded bg-white text-[#132043]"
                  required
                />
              </div>
            </div>

            {/* Row 3: Services */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                What Product are you interested in?
              </label>
              <input
                type="text"
                name="services"
                value={productName}
                className="w-full p-2 rounded bg-white text-[#132043]"
                required
              />
            </div>

            {/* Row 4: How did you hear about us? */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                Quantity Required
              </label>
              <input
                type="text"
                name="budget"
                className="w-full p-2 rounded bg-white text-[#132043]"
                required
              />
            </div>

            {/* Row 5: Message */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                Message
              </label>
              <textarea
                name="message"
                className="w-full p-2 rounded bg-white text-[#132043]"
                rows="4"
                required
              ></textarea>
            </div>

            {/* Row 6: Budget */}
            <div>
              <label className="text-lg font-bold text-[#132043]">
                How did you hear about us?
              </label>
              <input
                type="text"
                name="mode"
                className="w-full p-2 rounded bg-white text-[#132043]"
                required
              />
            </div>

            {/* Updated Submit Button */}
            <button
              type="submit"
              className="bg-[#132043] text-white font-bold px-4 py-2 rounded hover:bg-blue-800 disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Sending..." : "Send"}
            </button>
          </form>
        </section>
      </div>
      <div className="p-8"></div>
    </div>
  );
};

export default ContactForm;
