import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProducts } from "../../../Contexts/ProductContext";


const ImageCarousel = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const getImageSrc = (image) => {
    if (!image) return "/placeholder.jpg";
    if (image instanceof File) return URL.createObjectURL(image);
    if (typeof image === "string") return image;
    if (image.url) return image.url;
    return "/placeholder.jpg";
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-white p-4 rounded-lg max-w-4xl w-full mx-4">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
        <div className="relative h-96">
          <img
            src={getImageSrc(images[currentIndex])}
            alt={`Product ${currentIndex + 1}`}
            className="w-full h-full object-contain"
          />
          {images.length > 1 && (
            <>
              <button
                onClick={prevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100"
              >
                ←
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-100"
              >
                →
              </button>
            </>
          )}
        </div>
        <div className="flex justify-center mt-4 gap-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full ${
                currentIndex === index ? "bg-blue-500" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};



const AdminPanelDashboard = () => {
  const { products, deleteProduct } = useProducts();
  const navigate = useNavigate();
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  // Group products by name and combine their images
  const groupedProducts = products.reduce((acc, current) => {
    const key = current.name;
    if (!acc[key]) {
      acc[key] = {
        ...current,
        variants: [current],
        images: current.images || [],
      };
    } else {
      acc[key].variants.push(current);
      acc[key].images = [
        ...new Set([...acc[key].images, ...(current.images || [])]),
      ];
    }
    return acc;
  }, {});

  const uniqueProducts = Object.values(groupedProducts);

  const handleProductClick = (product) => {
    navigate(`products/view/${product._id}`);
  };

  const handleImageClick = (images, e) => {
    e.stopPropagation();
    setSelectedImages(images);
    setShowImageModal(true);
  };

  const getImageSrc = (image) => {
    if (!image) return "/placeholder.jpg";
    if (image instanceof File) return URL.createObjectURL(image);
    if (typeof image === "string") return image;
    if (image.url) return image.url;
    return "/placeholder.jpg";
  };

  const renderImageThumbnails = (product) => {
    const images = Array.isArray(product.images) ? product.images : [];
    const displayImages = images.slice(0, 3);

    return (
      <div className="flex items-center gap-2">
        {displayImages.map((image, index) => (
          <img
            key={index}
            src={getImageSrc(image)}
            alt={`${product.name}-${index}`}
            className="w-12 h-12 object-cover rounded cursor-pointer"
            onClick={(e) => handleImageClick(images, e)}
          />
        ))}
        {images.length > 3 && (
          <div
            className="w-12 h-12 bg-gray-200 rounded flex items-center justify-center cursor-pointer hover:bg-gray-300"
            onClick={(e) => handleImageClick(images, e)}
          >
            <span className="text-gray-600 font-semibold">
              +{images.length - 3}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto py-10 px-4">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Product Management Dashboard</h2>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
          onClick={() => navigate("products/create")}
        >
          Add New Product
        </button>
      </div>

      {/* Total Products Count */}
      <div className="mb-6">
        <p className="text-lg font-semibold">
          Total Products: <span className="text-blue-500">{products.length}</span>
        </p>
      </div>

      {/* Product Table */}
      <div className="overflow-x-auto bg-white rounded-lg shadow">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-50">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                S.No.
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Images
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Description
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {uniqueProducts.map((product, index) => (
              <tr
                key={product._id}
                className="hover:bg-gray-50 cursor-pointer transition-colors"
                onClick={() => handleProductClick(product)}
              >
                {/* Serial Number */}
                <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                {/* Images */}
                <td className="px-6 py-4 whitespace-nowrap">
                  {renderImageThumbnails(product)}
                </td>
                {/* Name */}
                <td className="px-6 py-4">{product.name}</td>
                {/* Category */}
                <td className="px-6 py-4">{product.category}</td>
                {/* Description */}
                <td className="px-6 py-4">
                  <div className="max-w-xs truncate">{product.description}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Image Modal */}
      {showImageModal && (
        <ImageCarousel
          images={selectedImages}
          onClose={() => setShowImageModal(false)}
        />
      )}
    </div>
  );
};



export default AdminPanelDashboard;
