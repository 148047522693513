import React from "react";
import img from "../../Assets/images/R7.png";

function Content3() {
  return (
    <div id="RA">
      <section className="text-justify bg-[#CACFD6] rounded-2xl">
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 2"
                className="w-full md:w-auto  object-cover rounded-2xl"
              />
            </div>

            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043]">
                Real-time Applications
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                SGES specializes in developing software that requires precise
                timing and responsiveness for critical industries. Our expertise
                includes:
              </p>
              <h5 className="font-bold text-lg mb-4">
                Our capabilities include:
              </h5>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">
                    Real-time Operating System (RTOS) Integration:
                  </span>{" "}
                  Seamless integration of VxWorks and QP RTEF into complex
                  systems.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Deterministic Scheduling:</span>{" "}
                  Designing software to meet strict timing constraints and
                  deadlines.
                </li>
                <li className="pt-4">
                  <span className="font-bold">High-Performance Computing:</span>{" "}
                  Optimizing software for real-time performance.
                </li>
                <li className="pt-4">
                  <span className="font-bold">ARINC 653 Compliance:</span>{" "}
                  Developing software that meets aviation safety standards for
                  real-time systems.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                Our deep understanding of real-time systems ensures software
                solutions with exceptional performance and reliability.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content3;
