import React from "react";

// Define the Content1 component
function Content1() {
  return (
    <div className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <h1 className="text-2xl font-bold mb-4 text-center">
            <span className="inline-block rounded-full px-3 py-1 text-2xl font-bold mr-2 mb-2">
              Our Approach to Conceptualization
            </span>
          </h1>
          <h2 className="text-lg mb-4 text-center text-[#132043]">
            We believe that successful product development begins with a strong
            conceptual foundation. Our approach combines technical expertise
            with a customer-focused mindset to deliver outstanding results.
          </h2>

          {/* Responsive grid for the first row */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 justify-center">
            {/* Box 1 */}
            <div className="flex-1 max-w-xs rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Collaborative Partnerships
                </div>
                <p className="text-base pb-2">
                  Working closely with clients to understand their needs,
                  challenges, and vision.
                </p>
              </div>
            </div>

            {/* Box 2 */}
            <div className="flex-1 max-w-xs rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  In-depth Requirements Analysis
                </div>
                <p className="text-base pb-2">
                  Using Business Process Modeling (BPM) to align requirements
                  with organizational goals.
                </p>
              </div>
            </div>

            {/* Box 3 */}
            <div className="flex-1 max-w-xs rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Tailored Solutions
                </div>
                <p className="text-base">
                  Crafting customized systems engineering and architecture
                  solutions to meet specific business and market needs.
                </p>
              </div>
            </div>
          </div>

          {/* Responsive grid for the second row */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-8 mt-8 justify-center">
            {/* Box 4 */}
            <div className="flex-1 max-w-xs rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Advanced Methodologies
                </div>
                <p className="text-base pb-2">
                  Utilizing Model-Based Systems Engineering (MBSE), SysML,
                  Design Structure Matrix (DSM), and frameworks like TOGAF and
                  DoD AF to develop robust, scalable architectures.
                </p>
              </div>
            </div>

            {/* Box 5 */}
            <div className="flex-1 max-w-xs rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Risk Mitigation
                </div>
                <p className="text-base pb-2">
                  Identifying and addressing risks early to ensure project
                  success.
                </p>
              </div>
            </div>

            {/* Box 6 */}
            <div className="flex-1 max-w-xs rounded overflow-hidden shadow-lg flex flex-col mx-auto">
              <div className="px-6 py-4 flex-grow">
                <div className="font-bold text-xl mb-2 text-[#132043]">
                  Continuous Improvement
                </div>
                <p className="text-base">
                  Staying current with industry trends and fostering a culture
                  of innovation.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-bold mb-4 p-8 text-[#132043] text-center">
            By leveraging these strategies and methodologies, we lay a strong
            foundation for your product, minimizing risks and enhancing success.
          </h2>
        </div>
      </section>
    </div>
  );
}

export default Content1;
