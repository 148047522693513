import React from "react";
import img from "../../Assets/images/R9.png";

function Content4() {
  return (
    <div>
      <section>
        <div className="pr-8 pl-8 pb-8">
          <div className="flex flex-col-reverse md:flex-row gap-4">
            <div className="w-full md:w-1/3 flex items-center justify-center lg:mr-12">
              <img src={img} alt="Picture 4" className="w-full rounded-2xl" />
            </div>
            <div className="w-full md:w-2/3">
              <h5>Our services include:</h5>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">
                    Standards Interpretation and Implementation:
                  </span>{" "}
                  Translating complex standards into actionable requirements.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    Process Development and Documentation:
                  </span>{" "}
                  Creating and maintaining compliant processes and
                  documentation.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    Risk Assessment and Management:
                  </span>{" "}
                  Identifying and mitigating potential risks.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Design Assurance: </span> Ensuring
                  designs meet regulatory requirements.
                </li>
                <li className="pt-4">
                  <span className="font-bold"> Certification Support: </span>{" "}
                  Guiding you through the certification process.
                </li>
              </ul>
              <h2 className="text-lg mb-4 p-8">
                Partner with SGES to ensure your products meet the highest
                industry standards.
              </h2>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content4;
