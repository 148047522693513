import React from "react";
import img from "../../Assets/images/R14.png";

function Content5() {
  return (
    <div id="Railway">
      <section className="p-8 rounded-2xl bg-[#CACFD6]">
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center">
            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Railway Industry
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The railway industry demands robust, reliable, and safe systems.
                SGES offers tailored solutions to meet the unique challenges of
                this sector. Our expertise includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">
                    Signaling and Train Control Systems:
                  </span>{" "}
                  Developing and implementing advanced signaling and train
                  control systems to enhance safety and efficiency.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Rolling Stock Systems: </span>{" "}
                  Providing solutions for trackside infrastructure, such as
                  point machines, level crossings, and communication systems.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Trackside Equipment:</span> Using
                  advanced tools to optimize antenna and sensor performance.
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    Maintenance and Diagnostics:
                  </span>{" "}
                  Developing software and systems for predictive maintenance and
                  fault diagnosis.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Cybersecurity:</span> Protecting
                  railway systems from cyber threats through robust security
                  measures.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                By combining our technical expertise with a deep understanding
                of railway operations, we deliver solutions that improve safety,
                efficiency, and passenger experience.
              </p>
            </div>
            {/* Image (1/3 width) - Now placed below text on mobile */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end mt-6 md:mt-0">
              <img
                src={img}
                alt="Railway systems"
                className=" md:w-full object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content5;
