import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const ProductContext = createContext();

export const useProducts = () => {
  return useContext(ProductContext);
};

export const ProductProvider = ({ children }) => {
  const API_URL = `${process.env.REACT_APP_API_URL}api/products`; // Update with your API URL
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const navigate = useNavigate();

  // Fetch a single product
  const getProduct = async (productId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/${productId}`);
      return response.data.data || response.data;
    } catch (err) {
      console.error("Error fetching product:", err);
      throw new Error("Failed to fetch product details");
    } finally {
      setLoading(false);
    }
  };

  // Fetch all products
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL);
      setProducts(response.data.data || response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching products:", err);
      setError("Failed to fetch products");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // Create a product
  const createProduct = async (productData) => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await axios.post(API_URL, productData, config);
      const newProduct = response.data.data || response.data;
      setProducts((prevProducts) => [...prevProducts, newProduct]);
      setError(null);
      return newProduct;
    } catch (err) {
      console.error("Error creating product:", err);
      setError("Failed to create product");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Update a product
  const updateProduct = async (productId, productData) => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const response = await axios.put(
        `${API_URL}/${productId}`,
        productData,
        config
      );
      const updatedProduct = response.data.data || response.data;
      setProducts((prevProducts) =>
        prevProducts.map((p) => (p._id === productId ? updatedProduct : p))
      );
      setError(null);
      return updatedProduct;
    } catch (err) {
      console.error("Error updating product:", err);
      setError("Failed to update product");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Delete a product
  const deleteProduct = async (productId) => {
    setLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await axios.delete(`${API_URL}/${productId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      if (res.status !== 200) {
        throw new Error("Error deleting product");
      }
      setProducts((prevProducts) =>
        prevProducts.filter((p) => p._id !== productId)
      );
      // navigate('/admin');
      setError(null);
      console.log('res productContext ',res);
      return res;
    } catch (err) {
      // console.error("Error deleting product:", err);
      setError("Failed to delete product");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        loading,
        error,
        getProduct,
        fetchProducts,
        createProduct,
        updateProduct,
        deleteProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
