// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/R15.png";

// Define the Home component
function Content4() {
  return (
    <div>
      <section id="IOT">
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Image Section */}
            <div className="w-full md:w-1/3 flex items-center justify-center">
              <img
                src={img}
                alt="Picture 2"
                className="w-full object-cover rounded-2xl"
              />
            </div>

            {/* Text Section */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                IoT and Connected Devices
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The Internet of Things (IoT) is revolutionizing industries
                worldwide. SGES offers comprehensive solutions to harness the
                power of connected devices. Our expertise includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">IoT Device Development:</span>{" "}
                  Designing, developing, and manufacturing IoT devices with
                  low-power consumption and robust connectivity.
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    Data Acquisition and Processing:
                  </span>{" "}
                  Collecting and analyzing data from IoT devices for valuable
                  insights.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Hardware Design: </span>{" "}
                  Seamlessly integrating IoT data with cloud platforms for
                  storage, processing, and analytics.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Security and Privacy: </span>
                  Implementing robust security measures to protect IoT devices
                  and data.
                </li>
                <li className="pt-2">
                  <span className="font-bold">IoT Platform Development:</span>{" "}
                  Creating custom IoT platforms for specific applications and
                  industries.
                </li>
              </ul>
              <p className="text-lg pt-2">
                By leveraging our expertise in hardware, software, and cloud
                technologies, we deliver innovative IoT solutions that drive
                business growth and improve operational efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content4;
