import React from "react";
import img from "../../Assets/images/R6.png";

function Content5() {
  return (
    <div id="AS">
      <section className="p-8 rounded-2xl bg-[#CACFD6]">
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Antenna/Sensors and Array Design
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                SGES provides comprehensive antenna, sensor, and array design
                services for a variety of applications, including wireless
                communication and environmental monitoring. Our expertise
                includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">Antenna Design: </span> Creating
                  custom antennas for various frequency bands and applications,
                  such as cellular, Wi-Fi, GPS, and RFID.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Array Design: </span> Designing
                  antenna arrays for enhanced gain, beamforming, and spatial
                  diversity.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Electromagnetic Simulation:</span>{" "}
                  Using advanced tools to optimize antenna and sensor
                  performance.
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    Prototype Development and Testing:
                  </span>{" "}
                  Building and testing prototypes to validate design parameters.
                </li>
              </ul>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                We deliver tailored solutions to meet your specific needs,
                ensuring optimal performance and reliability.
              </p>
            </div>

            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 2"
                className="w-full md:w-auto  object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content5;
