import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProducts } from "../../../../Contexts/ProductContext";
import DeleteButton from "./DeleteButton";

const ViewProducts = ({ products }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getProduct, deleteProduct } = useProducts();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProduct(id);
        setProduct(productData);
      } catch (err) {
        setError("Failed to load product details");
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id]);



  {
    loading && (
      <div className="container mx-auto py-10 px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6">
          <p className="text-center">Loading...</p>
        </div>
      </div>
    );
  }

  {
    error && (
      <div className="container mx-auto py-10 px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10 px-4">
      <div className="max-w-4xl mx-auto bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold">Product Details</h2>
          <div className="flex space-x-4">
            <button
              onClick={() => navigate(`/admin/products/edit/${id}`)}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              Edit
            </button>
            <DeleteButton/>
            {/* <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
              onClick={ handleDelete}
            >
              {isPending ? 'Deleting...':'Delete'}
            </button> */}
            <button
              onClick={() => navigate("/admin")}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition-colors"
            >
              Back
            </button>
          </div>
        </div>

        {product && (
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-2">Name</h3>
              <p className="text-gray-900">{product.name}</p>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-2">
                Category
              </h3>
              <p className="text-gray-900">{product.category}</p>
            </div>

            <div>
              <h3 className="text-lg font-medium text-gray-700 mb-2">
                Description
              </h3>
              <p className="text-gray-900">{product.description}</p>
            </div>

            {product.images && product.images.length > 0 && (
              <div>
                <h3 className="text-lg font-medium text-gray-700 mb-2">
                  Images
                </h3>
                <div className="grid grid-cols-3 gap-4">
                  {product.images.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={image.url}
                        alt={`Product ${index + 1}`}
                        className="w-full h-32 object-cover rounded"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewProducts;
