// Import the necessary dependencies
import React from "react";
import img from "../../Assets/images/Picture30.jpeg";

// Define the Home component
function Content5() {
  return (
    <div>
      <section className="p-8 rounded-2xl">
        <div className="p-8">
          <p className="mb-4 md:mb-0 md:mr-4 text-2xl font-bold ">
            <b>Silicon Glen Embedded Systems (SGES)</b> is your trusted partner
            for delivering tailored solutions across various industries. With a
            deep understanding of industry-specific challenges and a proven
            track record of success, we help you achieve your business goals.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Content5;
