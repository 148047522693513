import React from "react";
import img from "../../Assets/images/Picture17.jpeg";

function Content1() {
  return (
    <div id="C&C" className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center lg:gap-40 lg:ml-16 lg:mr-16">
            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-4xl font-bold mb-4 text-[#132043] text-left lg:mr-40">
                Certification and Compliance
              </h1>
              <p className="mb-4 md:mb-0 lg:mr-40 text-xl pt-8">
                At <b>Silicon Glen Embedded Systems (SGES)</b>, we understand
                the critical importance of compliance and certification in
                today's highly regulated industries. Our dedicated team of
                experts ensures that your products meet the strictest standards,
                mitigating risks and accelerating time-to-market.
              </p>
            </div>

            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 1"
                className="w-full md:w-auto h-80 object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
