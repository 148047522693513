import React from "react";

function Content1() {
  return (
    <div className='p-8 text-justify'>
      <section>
        <h1 className="text-2xl font-bold text-[#132043] text-left">
          Our focus
        </h1>
        <p className="mb-4  text-lg">
          <b>Silicon Glen Embedded Systems (SGES)</b> draws on the decades-long
          experience of its core team. These specialists possess in-depth
          expertise in safety and mission-critical avionics, radar systems,
          simulators, defense electronics, and medical systems development. This
          proven track record in high-integrity systems forms a solid foundation
          for tackling your most challenging embedded projects across diverse
          industries.
        </p>
        <p className="mb-4 text-lg">
          Our team's extensive experience in complying with rigorous aviation
          and medical standards ensures that we deliver not only innovative, but
          also eminently reliable solutions that meet the highest levels of
          excellence. <b>Silicon Glen Embedded Systems (SGES)</b> empowers
          clients to translate their most ingenious embedded systems concepts
          into reality.
        </p>
      </section>
    </div>
  );
}

export default Content1;
