import React from "react";
import img from "../../Assets/images/R1.png";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

function Content1() {
  return (
    <div className="text-justify">
      <section className="p-8 bg-[#CACFD6] rounded-2xl">
        <div className="flex flex-col-reverse md:flex-row gap-4 items-center lg:gap-40 lg:ml-16 lg:mr-16">
          {/* Text Content (2/3 width) */}
          <div className="w-full md:w-2/3">
            <h1 className="text-4xl font-bold text-[#132043] text-left lg:mr-40">
              Robust Embedded Systems for Mission and Safety Critical
              Applications
            </h1>
            <p className="mb-4 md:mb-0 lg:mr-40 text-xl pt-8">
              Delivering innovative, reliable, mission-critical systems and
              high-performance networking solutions across diverse industries,
              backed by decades of experience.
            </p>
            <Link
              to="/"
              style={{ color: "#ffc501" }}
              onClick={() => scrollToElement("Contact")}
            >
              <button className="text-[#CACFD6] p-2 rounded-2xl font-bold bg-[#132043] hover:text-[#132043]">
                Get in touch
              </button>
            </Link>
          </div>

          {/* Image (1/3 width) */}
          <div className="w-full md:w-1/3 flex justify-center md:justify-end">
            <img
              src={img}
              alt="Picture 1"
              className="w-full md:w-auto h-80 object-cover rounded-2xl"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
