import React from "react";
import img from "../../Assets/images/Picutre13.jpeg";

function Content4() {
  return (
    <div id="BSP">
      <section>
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center">
            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043]">
                BSP, Firmware, and Driver Development
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                At SGES, we bridge the gap between hardware and software with
                our expertise in BSP, firmware, and driver development. Our deep
                knowledge of various hardware platforms and operating systems
                allows us to create efficient, reliable software solutions.
              </p>
              <h5 className="font-bold text-lg mb-4">Our services include:</h5>
              <ul className="list-disc pl-5">
                <li>
                  <span className="font-bold">
                    Board Support Package (BSP) Development:{" "}
                  </span>{" "}
                  Customizing BSPs to optimize OS performance on specific
                  hardware platforms.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Firmware Development: </span>{" "}
                  Creating low-level software to control hardware components and
                  manage system resources.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Device Driver Development:</span>{" "}
                  Developing modules that facilitate communication between the
                  OS and hardware devices.
                </li>
                <li className="pt-4">
                  <span className="font-bold">Bootloader Development:</span>{" "}
                  Designing software to initiate the boot process and load the
                  OS.
                </li>
              </ul>
              <h2 className="text-lg mb-4 p-8">
                We deliver high-quality BSPs, firmware, and drivers to ensure
                seamless hardware-software integration, enhancing system
                performance and reliability.
              </h2>
            </div>

            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 4"
                className="w-full md:w-auto   object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content4;
