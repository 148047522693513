import React from "react";
import logo from "../../Assets/images/R2.png";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

const CardFlip = () => {
  return (
    <div className="p-4 rounded-2xl ">
      <div className="flip-card" tabIndex="0">
        <div className="flip-card-inner">
          <div className="flip-card-front rounded-lg overflow-hidden">
            <div className="image-container rounded-lg overflow-hidden">
              <div className="image rounded-lg overflow-hidden">
                <img src={logo} alt="Logo" className="rounded-lg" />
              </div>
              <div
                className={`absolute bottom-0 left-0 right-0 bg-[#132043]
          bg-opacity-50 text-center text-white font-bold text-lg transition-transform duration-300 ease-in-out p-4 `}
              >Systems Engineering</div>
            </div>
          </div>
          <div className="flip-card-back">
            <ul className="list-disc pl-5 p-4 text-left">
              <li>Requirements Analysis</li>
              <li>System Design</li>
              <li>Integration and Verification</li>
              <li>Validation and Verification</li>
              <button className="bg-[#CACFD6] p-2 rounded-2xl mt-4">
                <Link
                  to="/Services"
                  onClick={() => scrollToElement("SystemEngineering1")}
                  className="text-[#132043] font-bold"
                >
                  Read more
                </Link>
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardFlip;
