import React from "react";
import img from "../../Assets/images/graphic1.png";

function Content1() {
  return (
    <div className=''>
      <section className="relative mt-[-5%] rounded-2xl">
        <div className="">
          <img src={img} alt="Picture 1" className="scale-75" />
        </div>
      </section>
    </div>
  );
}

export default Content1;
