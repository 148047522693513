import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";

const ProtectedRoute = ({ element }) => {
  const { user } = useAuth();

  return user && user.role === "admin" ? (
    element
  ) : (
    <Navigate to="/AdminPanelLogin" />
  );
};

export default ProtectedRoute;
