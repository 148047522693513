import React, { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import { FaBars, FaCaretDown } from "react-icons/fa";
import ServicesDropdown from "./ServicesDropdown";
import IndustriesDropdown from "./IndustriesDropdown";
import ProductsDropdown from "./ProductsDropdown";
import logo from "../../Assets/images/logo.png";
import { useNavigate, Link } from "react-router-dom";

import { scroller } from "react-scroll";

const scrollToElement = (sectionName) => {
  setTimeout(() => {
    scroller.scrollTo(sectionName, {
      duration: 100,
      smooth: "easeInOutQuart",
      offset: 0,
    });
  }, 100);
};

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

const Navbar = () => {
  const navigate = useNavigate();
  const [isResponsive, setIsResponsive] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showIndustriesDropdown, setShowIndustriesDropdown] = useState(false);
  const [showProductsDropdown, setShowProductsDropdown] = useState(false);
  const navbarRef = useRef(null);

  const toggleResponsive = () => {
    setIsResponsive(!isResponsive);
  };

  const handleCloseNavbar = () => {
    if (isResponsive) {
      setIsResponsive(false);
    }
  };

  useOutsideClick(navbarRef, handleCloseNavbar);

  return (
    <div
      ref={navbarRef}
      className={`navbar ${isResponsive ? "responsive" : ""}`}
      id="myNavbar"
    >
      <Link to="/" className="logo-container">
        <img src={logo} alt="Logo" className="h-14 rounded-md" />
      </Link>
      <div className={`navbar-links`}>
        <div
          className="dropdown"
          onMouseEnter={() => setShowServicesDropdown(true)}
          onMouseLeave={() => setShowServicesDropdown(false)}
        >
          <button
            className="dropbtn"
            onClick={() => {
              navigate("/Services");
            }}
          >
            Services <FaCaretDown />
          </button>
          {showServicesDropdown && <ServicesDropdown />}
        </div>
        <div
          className="dropdown relative"
          onMouseEnter={() => setShowIndustriesDropdown(true)}
          onMouseLeave={() => setShowIndustriesDropdown(false)}
        >
          <button
            className="dropbtn"
            onClick={() => {
              navigate("/Industries");
            }}
          >
            Industries <FaCaretDown />
          </button>
          {showIndustriesDropdown && <IndustriesDropdown />}
        </div>

        <div
          className="dropdown relative"
          onMouseEnter={() => setShowProductsDropdown(true)}
          onMouseLeave={() => setShowProductsDropdown(false)}
        >
          <button
            className="dropbtn"
            onClick={() => {
              navigate("/EmbeddedP");
            }}
          >
            Products <FaCaretDown />
          </button>
          {showProductsDropdown && <ProductsDropdown />}
        </div>

        <Link to="/" onClick={() => scrollToElement("Contact")}>
          Contact us
        </Link>
      </div>

      <Link
        to="/"
        onClick={() => scrollToElement("Contact")}
        id="getQuoteButton"
        className=" button navbar"
      >
        Get a quote
      </Link>
      <Link className="icon" onClick={toggleResponsive}>
        <FaBars />
      </Link>
    </div>
  );
};

export default Navbar;
