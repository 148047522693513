import React, { useState } from "react";
import { Link } from "react-router-dom";

const HoverBox = ({ title, image, id, onClick }) => {
  console.log("HoverBox rendering with ID:", id, "Image:", image); // Debugging
  const [isHovered, setIsHovered] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleImageError = () => {
    console.log("Image failed to load:", image);
    setImageError(true);
  };

  // Determine background style based on whether we have a valid image
  const backgroundStyle =
    imageError || !image
      ? { backgroundColor: "#f0f0f0" } // Fallback gray background
      : { backgroundImage: `url(${image})` };

  return (
    <Link
      to={id ? `/SingleProduct/${id}` : "#"}
      className="relative w-80 h-80 bg-cover bg-center overflow-hidden rounded-lg m-4 bg-slate-600 block"
      style={backgroundStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {/* Hidden image to test loading */}
      {image && (
        <img src={image} alt="" className="hidden" onError={handleImageError} />
      )}

      {/* Optional: Display placeholder text if no image */}
      {(imageError || !image) && (
        <div className="flex items-center justify-center h-full text-white text-xl">
          {title}
        </div>
      )}

      {/* Title */}
      <div
        className={`absolute bottom-0 left-0 right-0 bg-[#132043]
          bg-opacity-50 text-center text-white font-bold text-2xl transition-transform duration-300 ease-in-out p-4 ${
            isHovered ? "translate-y-[-3rem]" : ""
          }`}
      >
        {title}
      </div>

      {/* Button */}
      {isHovered && (
        <button
          className="absolute bottom-0 left-0 w-full h-1/8 bg-[#132043]
          font-bold text-lg p-2 transition-opacity duration-300
          ease-in-out hover:bg-[#132043]"
        >
          <span style={{ color: "#ffc501" }}>Explore More</span>
        </button>
      )}
    </Link>
  );
};

export default HoverBox;
