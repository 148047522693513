export const fetchProductsByCategory = async (category) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/products/c/${category}`
    );
    const data = await response.json();
    console.log(data);

    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    return [];
  }
};

export const fetchProductById = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/products/${id}`
    );
    const data = await response.json();
    console.log("Product data:", data);

    return data;
  } catch (error) {
    console.error("Error fetching product details:", error);
    return null;
  }
};
