import React, { useState } from "react";
import { useProducts } from "../../../../Contexts/ProductContext";
import { useNavigate, useParams } from "react-router-dom";

const DeleteButton = () => {
  const params = useParams()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteProduct } = useProducts();
  const navigate = useNavigate();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteProduct(params.id);
      setAlert({ type: "success", message: "Product successfully deleted" });
      setTimeout(() => setAlert(null), 3000);
      navigate("/admin");
    } catch (error) {
      console.error("Error deleting product:", error);
      setAlert({ type: "error", message: "Product not deleted" });
    } finally {
      setIsDeleting(false);
      setShowConfirmDialog(false);
    }
  };

  return (
    <div className="relative">
      <button
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors"
        onClick={(e) => {
          e.stopPropagation();
          setShowConfirmDialog(true);
        }}
      >
        Delete
      </button>

      {showConfirmDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
            <h3 className="text-lg font-semibold mb-2">Confirm Deletion</h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this product? This action cannot
              be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 rounded text-gray-600 hover:bg-gray-100 transition-colors"
                onClick={() => setShowConfirmDialog(false)}
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 rounded bg-red-500 text-white hover:bg-red-600 transition-colors"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}

      {alert && (
        <div className="absolute top-10 right-0 z-40">
          <div
            className={`px-4 py-2 rounded shadow-lg flex items-center justify-between min-w-[200px] ${
              alert.type === "success"
                ? "bg-green-100 border border-green-500 text-green-800"
                : "bg-red-100 border border-red-500 text-red-800"
            }`}
          >
            <span>{alert.message}</span>
            <button
              onClick={() => setAlert(null)}
              className="ml-2 font-medium text-xl leading-none"
              aria-label="Close alert"
            >
              ×
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteButton;
