import React from "react";
import img2 from "../../Assets/images/R2.png";

function Content1() {
  return (
    <div id="SystemEngineering1" className="text-justify">
      <section>
        <div className="p-8">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center">
            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img2}
                alt="Picture 2"
                className="w-full md:w-auto  object-cover rounded-2xl"
              />
            </div>

            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Systems Engineering
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                At SGES, we use a rigorous systems engineering approach to
                develop products that meet customer needs and align with
                business goals. Our process includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">Requirements Analysis:</span>{" "}
                  Defining and documenting functional and non-functional
                  requirements.
                </li>
                <li className="pt-4">
                  <span className="font-bold">System Design:</span> Creating a
                  comprehensive architecture outlining components, interfaces,
                  and interactions.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    Integration and Verification:
                  </span>{" "}
                  Ensuring smooth integration of components and validating
                  performance.
                </li>
                <li className="pt-4">
                  <span className="font-bold">
                    Validation and Verification:
                  </span>{" "}
                  Testing the system to ensure it meets customer needs and
                  standards.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
