import React from "react";
import img from "../../Assets/images/Picture27.jpeg";

function Content4() {
  return (
    <div id="Auto">
      <section>
        <div className="p-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 2"
                className="w-full md:w-auto object-cover rounded-2xl"
              />
            </div>

            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Automotive Industry
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The automotive industry is undergoing a rapid transformation
                driven by technology and innovation. SGES offers comprehensive
                solutions to address the evolving needs of this sector. Our
                expertise includes:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">
                    Embedded Systems Development:
                  </span>{" "}
                  Designing and developing embedded systems for powertrain,
                  chassis, and body electronics.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Software Development:</span>{" "}
                  Creating software solutions for advanced driver assistance
                  systems (ADAS), infotainment, and vehicle connectivity.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Hardware Design: </span>{" "}
                  Developing automotive-grade hardware components, including
                  sensors, actuators, and controllers.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Functional Safety: </span>{" "}
                  Ensuring product safety through rigorous testing and
                  compliance with standards like ISO 26262.
                </li>
                <li className="pt-2">
                  <span className="font-bold">Cybersecurity:</span> Protecting
                  vehicles from cyber threats with robust security measures.
                </li>
              </ul>
              <p className="text-lg pt-2">
                By combining our technical expertise with a deep understanding
                of the automotive industry, we deliver solutions that enhance
                vehicle performance, safety, and user experience.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content4;
