import React from "react";
import img from "../../Assets/images/Picture1.jpg";

function Content1() {
  return (
    <div id="Conceptualization" className="text-justify">
      <section>
        <div className="p-8 bg-[#CACFD6] rounded-2xl">
          <div className="flex flex-col-reverse md:flex-row gap-4 items-center lg:gap-40 lg:ml-16 lg:mr-16">
            {/* Text Content (2/3 width) */}
            <div className="w-full md:w-2/3">
              <h1 className="text-4xl font-bold mb-4 text-[#132043] text-left lg:mr-40">
                Conceptualization Services
              </h1>
              <p className="mb-4 md:mb-0 lg:mr-40 text-lg pt-8">
                A strong concept is key to a successful product. At{" "}
                <b>Silicon Glen Embedded Systems (SGES)</b>, we focus on the
                critical early stages of product development. Our
                conceptualization services transform your vision into a tangible
                reality by combining systems engineering and architecture to
                analyze requirements, define boundaries, and create a solid
                blueprint. This approach reduces risks, optimizes resources, and
                ensures efficient development and deployment.
              </p>
            </div>

            {/* Image (1/3 width) */}
            <div className="w-full md:w-1/3 flex justify-center md:justify-end">
              <img
                src={img}
                alt="Picture 1"
                className="w-full md:w-auto h-60 object-cover rounded-2xl"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content1;
