import React from "react";
import img from "../../Assets/images/R12.png";

function Content2() {
  return (
    <div id="Aero">
      <section>
        <div className="p-8">
          <div className="flex flex-col  md:flex-row gap-4 items-center">
            <div className="w-full md:w-1/3 sm:h-80 flex justify-center">
              <img
                src={img}
                alt="Picture 2"
                className=" object-cover rounded-2xl"
              />
            </div>
            <div className="md:w-2/3">
              <h1 className="text-2xl font-bold mb-4 text-[#132043] pt-8">
                Aerospace and Defense
              </h1>
              <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                The aerospace and defense sectors require unparalleled
                reliability, safety, and performance. SGES offers a complete
                suite of services designed to meet the exacting demands of this
                industry, including:
              </p>
              <ul className="list-disc pl-5 text-lg">
                <li>
                  <span className="font-bold">
                    Mission-critical software development:
                  </span>{" "}
                  <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                    We create robust, real-time software solutions that
                    prioritize reliability and safety, ensuring flawless
                    performance in critical aerospace and defense applications.
                  </p>
                </li>
                {/* Rest of your list items */}
                <li className="pt-2">
                  <span className="font-bold">
                    Hardware design and engineering for extreme environments:
                  </span>{" "}
                  <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                    Our hardware is engineered to withstand the harshest
                    conditions, including extreme temperatures, high radiation,
                    and intense mechanical stress.
                  </p>
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    Certification and compliance support (DO-178, DO-254,
                    MIL-STD):
                  </span>{" "}
                  <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                    We provide comprehensive support for industry certifications
                    and standards, ensuring our products meet all regulatory and
                    safety requirements.
                  </p>
                </li>
                <li className="pt-2">
                  <span className="font-bold">
                    System integration and thorough testing:
                  </span>{" "}
                  <p className="mb-4 md:mb-0 md:mr-4 text-lg">
                    We integrate complex systems with precision, followed by
                    rigorous testing to ensure seamless operation and
                    reliability in real-world conditions.
                  </p>
                </li>
                <p className="text-lg pt-2">
                  SGES delivers solutions that meet the stringent requirements
                  of the aerospace and defense industries, ensuring maximum
                  performance and safety.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content2;
